{
  "API_BASE_URL": {
    "test": "https://ebp-api-service-preprod.ebp-dev.ckw.ch",
    "dev": "https://ebp-api-service-preprod.ebp-dev.ckw.ch",
    "preProd": "https://ebp-api-service-preprod.ebp-dev.ckw.ch",
    "demo": "https://ebp-api-service-demo.ebp-dev.ckw.ch",
    "prod": "https://ebp-api-service.ebp.ckw.ch",
    "mock": "http://localhost:13000"
  },
  "FEATURE_ZEV_APP": "1"
}
