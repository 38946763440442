import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import { GridValidRowModel } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useMutation, useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash-es"
import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { UtilityUnitResponse, UtilityUnitState } from "../../../../data/generated-sources/openapi"
import {
  UtilityUnitParticipant,
  UtilityUnitParticipationParticipant,
} from "../../../../domain/participant/Participant.Model"
import {
  deleteUtilityUnitParticipation,
  getAllParticipationsForUtilityUnit,
} from "../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { CancelButton, PrimaryButton } from "../../../../uikit/button"
import { DataGridTable, dateSortComparator, EmptyTableRow } from "../../../../uikit/dataGridTable"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { HomeFilledIcon, OpenIcon, RemoveIcon } from "../../../../uikit/Shared.Icon"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../Shared.Utils"
import { UtilityUnitParticipantMoveInCell } from "./participations/UtilityUnitParticipantMoveInCell"
import { UtilityUnitParticipantMoveOutCell } from "./participations/UtilityUnitParticipantMoveOutCell"

export const UtilityUnitParticipationsTable = ({ utilityUnit }: { utilityUnit: UtilityUnitResponse }) => {
  const history = useHistory()
  const { t } = useTranslation("utilityUnitParticipations")
  const [modifyError, setModifyError] = useState<AxiosErrorDataType | undefined>()

  const [dialogProps, setDialogProps] = useState<{ show: boolean; utilityUnitId: string; participationId: string }>({
    show: false,
    utilityUnitId: "",
    participationId: "",
  })

  const {
    data: participations,
    isFetching,
    remove: removeParticipation,
    refetch,
  } = useQuery(["getAllParticipationsForUtilityUnit"], () => getAllParticipationsForUtilityUnit(utilityUnit?.id), {
    enabled: !!utilityUnit?.id,
    ...useQueryDefaultOptions,
  })

  useEffect(() => {
    return () => removeParticipation()
  }, [removeParticipation])

  const handlRefetch = useCallback(() => {
    setModifyError(undefined)
    refetch()
  }, [refetch])

  const { mutate: deleteParticipation, isLoading: isDeleting } = useMutation(
    ["deleteParticipation"],
    (deleteData: { utilityUnitId: string; participationId: string }) =>
      deleteUtilityUnitParticipation(deleteData.utilityUnitId, deleteData.participationId),
    {
      onSuccess: () => handlRefetch(),
      onError: setModifyError,
    },
  )
  const currentParticipant = participations?.find(
    (participant: UtilityUnitParticipationParticipant) => participant.isCurrent,
  )

  const MoveInParticipantLayout = useCallback(
    ({ value }: GridValidRowModel) => (
      <UtilityUnitParticipantMoveInCell
        participation={value}
        onUpdateError={setModifyError}
        onUpdateSuccess={() => handlRefetch()}
      />
    ),
    [handlRefetch],
  )

  const MoveOutParticipantLayout = useCallback(
    ({ value }: GridValidRowModel) => (
      <UtilityUnitParticipantMoveOutCell
        participation={value}
        onUpdateError={setModifyError}
        onUpdateSuccess={() => handlRefetch()}
      />
    ),
    [handlRefetch],
  )

  const ParticipantInfo = useCallback(
    (participant?: UtilityUnitParticipant) => {
      return (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
          <HomeFilledIcon color="primary" />
          {!isEmpty(participant) ? (
            <>
              <Typography variant="body1">{participant?.fullName}</Typography>
              <OpenIcon
                color="disabled"
                fontSize="small"
                cursor="pointer"
                onClick={() =>
                  participant && history.push(`/utility-units/${utilityUnit?.id}/participants/${participant.id}`)
                }
              />
            </>
          ) : (
            <Typography variant="body1">{t("participation.noParticipation")}</Typography>
          )}
        </Stack>
      )
    },
    [history, t, utilityUnit?.id],
  )

  const openDialog = (utilityUnitId: string, participationId: string) => {
    setDialogProps(() => {
      return {
        show: true,
        utilityUnitId,
        participationId,
      }
    })
  }

  const closeDialog = () => {
    setDialogProps((prevState) => {
      return {
        ...prevState,
        show: false,
      }
    })
  }

  const DeleteButton = useCallback(
    ({ value }: GridValidRowModel) => {
      if (!value?.isLatestTenant) return <></>

      return (
        <Tooltip title={t("tooltip.delete") || ""}>
          <IconButton onClick={() => openDialog(value.utilityUnitId, value.id)} color="default" size="large">
            {isDeleting ? <CircularProgress size={20} /> : <RemoveIcon />}
          </IconButton>
        </Tooltip>
      )
    },
    [t, isDeleting],
  )

  const FirstMoveIn = useCallback(() => {
    if (utilityUnit?.activeState !== UtilityUnitState.ACTIVE) return <EmptyTableRow />
    return null
  }, [utilityUnit, handlRefetch])

  const participantsColumns: Array<GridBaseColDef> = [
    {
      field: "participant",
      headerName: t("tableHeader.attendees")?.toUpperCase(),
      flex: 250,
      renderCell: ({ value }) => <ParticipantInfo {...value} />,
    },
    {
      field: "moveInDate",
      headerName: t("tableHeader.moveInDate")?.toUpperCase(),
      flex: 150,
      sortComparator: dateSortComparator,
      valueGetter: (_, row) => row,
      renderCell: MoveInParticipantLayout,
    },
    {
      field: "moveOutDate",
      headerName: t("tableHeader.moveOutDate")?.toUpperCase(),
      flex: 150,
      sortComparator: dateSortComparator,
      valueGetter: (_, row) => row,
      renderCell: MoveOutParticipantLayout,
    },
    {
      field: "delete",
      headerName: "",
      flex: 50,
      sortable: false,
      valueGetter: (_, row) => row,
      renderCell: DeleteButton,
    },
  ]

  return (
    <Paper>
      <Typography variant="h4" p={2} fontSize={24} fontWeight={300} textTransform={"uppercase"} fontFamily="Montserrat">
        {t("participation.title")}
      </Typography>
      <ErrorAlert
        visible={!!modifyError}
        message={t(`error-codes:${modifyError?.response?.data?.code || modifyError?.code || "OTHER"}`)}
      />
      <Stack p={2} mb={2}>
        <Stack direction="row" spacing={1} mb={3} alignItems="flex-end" justifyContent="flex-start">
          <HomeFilledIcon color="primary" />
          <Typography fontWeight="bold">{t("participation.currentParticipant")}</Typography>
        </Stack>
        <Typography fontWeight="bold">{t("participation.name")}</Typography>
        {currentParticipant?.participant ? (
          <ParticipantInfo {...currentParticipant.participant} />
        ) : (
          <Typography variant="body1">{t("participation.noParticipation")}</Typography>
        )}
      </Stack>

      <DataGridTable<UtilityUnitParticipationParticipant>
        rows={participations || []}
        columns={participantsColumns}
        loading={isFetching || isDeleting}
        redirectParamName={"id"}
        redirectUrl={location.pathname}
        hideFooter={!participations?.length}
        rowHeight={!participations?.length ? 30 : 80}
        slots={{ noRowsOverlay: FirstMoveIn }}
        defaultPageSize={10}
      />

      <Dialog open={dialogProps.show} onClose={closeDialog} fullWidth>
        <Box p={2}>
          <DialogContent>{t("delete.dialog.body")}</DialogContent>
          <DialogActions sx={{ justifyContent: "space-between", py: 2 }}>
            <CancelButton label={t("shared:dialog.close.cta")} onClick={closeDialog} />
            <PrimaryButton
              label={t("shared:dialog.confirm.cta")}
              onClick={() => {
                closeDialog()
                deleteParticipation({
                  utilityUnitId: dialogProps.utilityUnitId,
                  participationId: dialogProps.participationId,
                })
              }}
            />
          </DialogActions>
        </Box>
      </Dialog>
    </Paper>
  )
}
