import i18next from "i18next"
import { from, map, mergeMap, Observable, of } from "rxjs"
import { DOMAIN_DEPENDENCIES } from "../../app/App.Config"
import { AuthResetPasswordApiBody } from "../../app/auth/AuthResetPassword/AuthResetPassword.Component"
import { AuthenticationResponse } from "../../data/generated-sources/openapi"
import { apiCall, apiHeaders } from "../Domain.Calls"
import { DomainDependencies } from "../Domain.Dependencies"
import { DomainResponse } from "../Domain.Response"
import { lastSelectedArea } from "./Auth.Helpers"
import { AuthSession, AuthType } from "./Auth.Model"

const DEPS: DomainDependencies = DOMAIN_DEPENDENCIES
const APP_NAME: string = DEPS.config.appName

export const createAdminSession = (
  emailAddress: string,
  password: string,
  authType: AuthType,
): Observable<DomainResponse<AuthSession>> => {
  return apiCall(
    from(createAdminSessionFor(emailAddress, password, authType)).pipe(
      mergeMap((authResponse: AuthenticationResponse) => {
        DEPS.cookie.createCookieBearerToken(APP_NAME, authResponse.token)
        DEPS.cookie.createCookieRefreshToken(APP_NAME, authResponse.refreshToken)
        DEPS.cookie.createCookieAuthType(APP_NAME, authType)
        return completeLogin(authType)
      }),
    ),
  )
}

export const createAdminSessionFor = (
  emailAddress: string,
  password: string,
  authType: AuthType,
): Observable<AuthenticationResponse> => {
  switch (authType) {
    case AuthType.ADMINISTRATOR:
      return from(
        DEPS.adminSessionApi.createAdminSession({
          username: emailAddress,
          password: password,
        }),
      ).pipe(map((response) => response.data))
    case AuthType.MANAGER:
      return from(
        DEPS.customerSessionApi.createCustomerSession({
          username: emailAddress,
          password: password,
        }),
      ).pipe(map((response) => response.data))
  }
}

export const refreshAdminSession = (): Observable<DomainResponse<AuthSession>> => {
  const authType = DEPS.cookie.readCookieAuthType(APP_NAME) === "MANAGER" ? AuthType.MANAGER : AuthType.ADMINISTRATOR
  return apiCall(
    refreshAdminSessionFor(authType).pipe(
      mergeMap((authResponse) => {
        DEPS.cookie.createCookieBearerToken(APP_NAME, authResponse.token)
        DEPS.cookie.createCookieRefreshToken(APP_NAME, authResponse.refreshToken)
        return completeLogin(authType)
      }),
    ),
  )
}

export const refreshAdminSessionFor = (authType: AuthType): Observable<AuthenticationResponse> => {
  switch (authType) {
    case AuthType.ADMINISTRATOR:
      return from(
        DEPS.adminSessionApi.refreshAdminSession({
          refreshToken: DEPS.cookie.readCookieRefreshToken(APP_NAME),
        }),
      ).pipe(map((response) => response.data))
    case AuthType.MANAGER:
      return from(
        DEPS.customerSessionApi.refreshCustomerSession({
          refreshToken: DEPS.cookie.readCookieRefreshToken(APP_NAME),
        }),
      ).pipe(map((response) => response.data))
  }
}

const completeLogin = (authType: AuthType): Observable<AuthSession> => {
  switch (authType) {
    case AuthType.ADMINISTRATOR:
      return of({
        authType: authType,
        areas: [],
      })
    case AuthType.MANAGER:
      return from(getManagerAreas(authType))
  }
}

export const forgottenPassword = (emailAddress: string): Observable<DomainResponse<boolean>> =>
  apiCall(from(DEPS.adminSessionApi.adminForgotPassword(emailAddress)).pipe(map(() => true)))

export const newAccountPassword = (token: string, newPassword: string): Observable<DomainResponse<boolean>> =>
  apiCall(from(DEPS.adminSessionApi.adminNewAccountPassword(token, { password: newPassword })).pipe(map(() => true)))

export const adminNewActivationEmail = (token: string): Observable<DomainResponse<boolean>> =>
  apiCall(from(DEPS.adminSessionApi.adminNewActivationEmail(token)).pipe(map(() => true)))

export const resetPassword = (passwordObj: AuthResetPasswordApiBody): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(DEPS.customerProfileApi.updateCustomerProfilePassword(passwordObj, apiHeaders(DEPS))).pipe(map(() => true)),
  )
}

export const getManagerAreas = async (authType: AuthType): Promise<AuthSession> => {
  const { data: areasData } = await DEPS.customerAreaApi.customerGetAllAreas(apiHeaders(DEPS))
  return {
    authType,
    lastSelectedArea: lastSelectedArea(areasData),
    areas: [...areasData].sort((a, b) => a.name.localeCompare(b.name, i18next.language)),
  }
}
