import { MeterResponse } from "../../../../../data/generated-sources/openapi"
import { UtilityUnitMetersApiEnum } from "../Components.Enums"
import { metersColumnComparator } from "../meters/Meters.Comparator"
import { MetersColumns } from "../meters/Meters.Enum"
import { UtilityUnitTableMeters } from "../utilityUnits/UtilityUnitMetersTable"

export const UTILITY_UNIT_METERS_LIST_COMPONENT_CONFIG = {
  utilityUnitMeters: {
    name: "utilityUnitMeters",
    header: {
      title: "meters:table.title",
      showButton: true,
      showFilter: false,
      redirectUrl: () => `${location.pathname}/meter/create`,
    },
    columnEnums: MetersColumns,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultRowsPerPage: 10,
    defaultSorting: {
      column: MetersColumns.METER_ID,
      direction: "desc",
    },
    tableHeaders: [
      {
        column: MetersColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: MetersColumns.METER_ID,
        label: "meters:label.meterIdSpec",
        width: "40%",
        orderable: true,
      },
      {
        column: MetersColumns.MEDIUM,
        label: "meters:label.medium",
        width: "20%",
        orderable: true,
      },
      {
        column: MetersColumns.PRICING_MODEL_INFRASTRUCTURE,
        label: "meters:label.priceModelInfrastructure",
        width: "35%",
        orderable: true,
      },
    ],
    columnComparator: metersColumnComparator,
    renderTableRows: (item: MeterResponse) => <UtilityUnitTableMeters {...item} key={item.id} />,
  },
}

export const UTILITY_UNIT_METERS_API_CONFIG = {
  utilityUnitMeters: UtilityUnitMetersApiEnum,
}
