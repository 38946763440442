import { Paper, Typography } from "@mui/material"
import { QueryObserverResult } from "@tanstack/query-core/src/types"
import { AxiosResponse } from "axios"
import { ContractAdminResponse, ProductType } from "../../../../../data/generated-sources/openapi"
import { getAreaById } from "../../../../../domain/portal/admin/areas/Areas.Repository"
import { getProductByIdNew } from "../../../../../domain/portal/admin/products/Products.Repository"
import DateUtils from "../../../../../services/utils/DateUtils"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"
import { DataItemBoxNoValue } from "../../../../../uikit/box/DataItemBox"
import RedirectButton from "../../../../../uikit/button/RedirectButton"
import { getExactParamFromUrl, getTranslateValue } from "../../../../Shared.Utils"
import ContractAnalysis from "../../../shared/analiyse/ContractAnalysis"
import ProductHistory from "../../../shared/productHistory/ProductHistory"
import { TariffEnergySupplier } from "../../../shared/tariffs/tariffEnergySupplier/TariffEnergySupplier"
import { AdminPortalRouteEnums } from "../../AdminPortal.Enums"
import { ContractComment } from "../../contractsManagement/comments/ContractComment"
import { ContractElectricityPricesSection } from "../../contractsManagement/electricityPrices/ContractElectricityPricesSection"
import ManagerListComponent from "../../contractsManagement/managers/ManagerList.Component"
import { ContractMasterDataButtons } from "../../contractsManagement/masterData/ContractMasterDataButtons"
import MediumWrapperComponent from "../../contractsManagement/medium/MediumWrapper.Component"
import { COMPONENTS_CONFIG } from "../../sharedComponentsConfig/Components.Config"
import ContractManagementParticipantBillingComponent from "../../sharedComponentsConfig/participantBilling/ContractManagementParticipantBilling.Component"
import { ContractParticipants } from "../../sharedComponentsConfig/participants/ContractParticipants"
import ContractManagementServiceBillingComponent from "../../sharedComponentsConfig/serviceBilling/ContractManagementServiceBilling.Component"
import SharedListComponent from "../../sharedListComponet/SharedList.Component"
import GetSpecialValueComponent from "../components/GetSpecialValue.Component"
import { GetContractManagementHook } from "../CustomGetHooks"
import { MasterDataEnum } from "../MasterData.Enum"
import MasterDataChildComponent from "../MasterDataChild.Component"

export const CONTRACT_MANAGEMENT_MASTER_DATA_CONFIG = {
  contractManagement: {
    name: getTranslateValue("contracts:breadcrumps.contracts"),
    componentName: "contractMasterData",
    useQueryHook: (paramId: string) => GetContractManagementHook({ id: paramId }),
    renderingContent: [
      {
        firstColumn: {
          label: "",
          getValue: (response: AxiosResponse<ContractAdminResponse>) => response.data.activeState,
        },
        secondColumn: {
          label: "",
          getComponent: (response: AxiosResponse<ContractAdminResponse>) => (
            <RedirectButton redirectUrl={`/management-contracts/${response.data.id}/master-data/update`} />
          ),
        },
      },
      {
        firstColumn: {
          label: "",
          getComponent: (response: AxiosResponse<ContractAdminResponse>) =>
            response.data.name ? (
              <Typography fontSize={36} fontWeight={300}>
                {response.data.name}
              </Typography>
            ) : (
              <DataItemBoxNoValue />
            ),
        },
      },
      {
        firstColumn: {
          label: "contracts:form.field.startDate",
          getValue: (response: AxiosResponse<ContractAdminResponse>) =>
            DateUtils.getDeFormatWithDots(response.data.startDate),
        },
        secondColumn: {
          label: "contracts:form.field.endDate",
          getComponent: (response: AxiosResponse<ContractAdminResponse>) =>
            response?.data?.endDate ? (
              DateUtils.getDeFormatWithDots(response.data.endDate)
            ) : (
              <DataItemBoxNoValue title={"contracts:form.field.endDate"} />
            ),
        },
      },
      {
        firstColumn: {
          label: "contracts:form.field.product",
          getComponent: (response: AxiosResponse<ContractAdminResponse>) => (
            <GetSpecialValueComponent
              getMethod={() => getProductByIdNew(response.data.productId)}
              propertyName="name"
              uniqueKey={getUniqueId()}
              label={"contracts:form.field.product"}
            />
          ),
        },
        secondColumn: {
          label: "contracts:table.header.area",
          getComponent: (response: AxiosResponse<ContractAdminResponse>) => (
            <GetSpecialValueComponent
              getMethod={() => response.data.areaId && getAreaById(response.data.areaId)}
              propertyName="name"
              uniqueKey={getUniqueId()}
              label={"contracts:table.header.area"}
            />
          ),
        },
      },
      {
        firstColumn: {
          label: "",
        },
        row: {
          label: "",
          getComponentRow: (response: QueryObserverResult<ContractAdminResponse>) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ContractMasterDataButtons {...response.data} refetch={response?.refetch} />
          ),
        },
      },
    ],
    childrenWithResponse: (response: AxiosResponse<ContractAdminResponse>) => (
      <>
        <ContractComment {...response.data} />
        <ProductHistory contractId={response.data.id} title={getTranslateValue("products:productHistory.title")} />
        <MasterDataChildComponent configName={MasterDataEnum.CONTRACT_MANAGEMENT_CONTACT} />
        <ManagerListComponent />
        <SharedListComponent
          additionalRouteQueryParams={{ areaId: response?.data?.areaId }}
          configNew={COMPONENTS_CONFIG.contractManagementBuilding}
          getParamFromUrl={() => getExactParamFromUrl("/management-contracts/:id", AdminPortalRouteEnums.id)}
        />
        <MediumWrapperComponent />
        <MasterDataChildComponent configName={MasterDataEnum.BILLING_SETTINGS} />
        {response?.data?.product?.productType === ProductType.ZEV && (
          <Paper sx={{ pt: 5 }}>
            <TariffEnergySupplier contractId={response?.data?.id} />
          </Paper>
        )}
        <ContractElectricityPricesSection />
        <ContractParticipants {...response?.data} />
        <ContractManagementParticipantBillingComponent {...response?.data} />
        <ContractManagementServiceBillingComponent {...response?.data} />
        <ContractAnalysis
          contractId={response?.data.id}
          contractProductType={response.data.product?.productType as string}
        />
      </>
    ),
  },
}
