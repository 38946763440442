import { ParticipantGpType, ParticipantPersonalDataSalutationEnum } from "../../data/generated-sources/openapi"
import {
  ParticipantAdminUpdateRequest,
  ParticipantResponse,
  ParticipationResponse,
} from "../../data/generated-sources/openapi/api"

export interface UtilityUnitParticipant extends ParticipantResponse {
  fullName?: string
}

export interface UtilityUnitParticipationParticipant extends ParticipationResponse {
  participant?: UtilityUnitParticipant
  prevParticipantMoveOut?: string
  nextParticipantMoveIn?: string
  isLatestTenant: boolean
}

export enum UtilityUnitMoveInType {
  EXISTING = "EXISTING",
  VACANCY = "VACANCY",
  NONE = "NONE",
  CREATE = "CREATE",
}

export interface UtilityUnitMoveInFormData {
  participantId: string
  moveInDate: number
  moveInType?: UtilityUnitMoveInType
}

export enum ParticipantAddressCountry {
  Schweiz = "Schweiz",
}

export const initialParticipantValues: ParticipantAdminUpdateRequest = {
  gpType: ParticipantGpType.PERSON,
  personalData: {
    firstName: "",
    lastName: "",
    firstNameSecondPerson: "",
    lastNameSecondPerson: "",
    salutation: ParticipantPersonalDataSalutationEnum.MR,
  },
  contactDetails: {
    email: undefined,
    mobile: undefined,
    phone: undefined,
  },
  domicileAddress: {
    city: "",
    postCode: "",
    houseNumber: "",
    street: "",
    land: ParticipantAddressCountry.Schweiz,
    co: "",
    poBox: "",
  },
}

export const participantAddressCountryKeys = Object.keys(ParticipantAddressCountry)

export const salutationKeys = Object.keys(ParticipantPersonalDataSalutationEnum)

export const businessPartnerTypeKeys = Object.keys(ParticipantGpType)

export interface Participation {
  id: string
  moveInDate: string
  moveOutDate?: string
  participantId?: string
  bills: string[]
  utilityUnit: string
}

export interface ParticipantUpsert {
  id: string
  businessPartnerName: string
  businessPartnerType: string
  salutation: string
  title: string
  firstName: string
  lastName: string
  firstNameSecondPerson: string
  lastNameSecondPerson: string
  contactTelephone: string
  contactMobile: string
  contactEmail: string
  addressStreet: string
  addressHouseNumber: string
  addressCO: string
  addressPostBox: string
  addressPostCode: string
  addressCity: string
  addressCountry: string
}
