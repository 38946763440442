import TableCell from "@mui/material/TableCell"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { StatusType } from "../../../../../domain/Domain.Model"

import { MeterResponse } from "../../../../../data/generated-sources/openapi"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"

interface MetersBuildingTableInterface {
  itemConfig: MeterResponse
}

const MetersBuildingTableComponent = ({ itemConfig }: MetersBuildingTableInterface) => {
  const { t } = useTranslation("meters")
  const history = useHistory()

  return (
    <TableRowClickable<MeterResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={(metersItem) => {
        if (metersItem.id) {
          history.push(`/meters/${itemConfig.id}`)
        }
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig.meteringCode}</TableCell>
      <TableCell align="left">{t(`label.${itemConfig.medium}`)}</TableCell>
      <TableCell align="left">{t(`shared:label.pricingModel.${itemConfig.pricingModel}`)}</TableCell>
    </TableRowClickable>
  )
}

export default MetersBuildingTableComponent
