import { ThemeProvider } from "@mui/material"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { useEffect, useMemo } from "react"
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom"
import "../../assets/css/base.css"
import { AuthSession, AuthType } from "../../domain/auth/Auth.Model"
import { ProgressIndicator } from "../../uikit/progress/ProgressIndicator"
import { AuthResetPasswordSuccessfulMassageComponent } from "../auth/AuthResetPassword/AuthResetPassword.Component"
import HelmetHandler from "../auth/helmetHandler"
import { COOKIE_ITEMS } from "../config/outsorceConstants"
import { nonAuthRoutes } from "../config/routesConfig"
import { AdminPortalRouteParams, AdminPortalRoutes } from "../portal/admin/AdminPortal.Routes"
import { ManagerPortalRoutes } from "../portal/manager/ManagerPortal.Routes"
import { Onboarding } from "../portal/manager/onboarding/Onboarding"
import { ONBOARDING_REQUIRED_COOKIE_NAME, readCookie } from "../Shared.Cookie"
import { firstViewState, ViewState } from "../Shared.Reducer"
import { ckwTheme } from "../Shared.Theme"
import { mapDispatchToProps } from "./AppContainer.Connect"
import { AppContainerState } from "./AppContainer.Reducer"
import { AppConfirmDialog } from "./views/AppConfirmDialog"
import { AppContainerView } from "./views/AppContainerView"
import { AppPageView } from "./views/AppPageView"
import { AppToast } from "./views/AppToast"
import { AdminHeaderViewProps } from "./views/header/AdminHeaderView"
import { AppHeaderView } from "./views/header/AppHeaderView"
import { CustomerHeaderViewProps } from "./views/header/CustomerHeaderView"
import { AdminNavigationViewProps } from "./views/navigation/AdminNavigationView"
import { AppNavigationView } from "./views/navigation/AppNavigationView"
import { CustomerNavigationViewProps } from "./views/navigation/CustomerNavigationView"

export interface AppContainerComponentProps
  extends AppContainerState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export interface AuthTypeProps {
  authType?: AuthType
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
})
export default withRouter((props: AppContainerComponentProps) => {
  const {
    appConfirmDialogState,
    appToastState,
    dialogAbortClick,
    dialogConfirmClick,
    toastClose,
    sessionExpired,
    location,
    logout,
    viewState,
    checkSession,
  } = props

  const authType = useMemo(() => viewState?.domainResult?.authType, [viewState])

  useEffect(() => {
    HelmetHandler.configureHamlet()
    if (sessionExpired) {
      logout(location.pathname)
    }
  }, [sessionExpired, logout, location.pathname])

  useEffect(() => {
    HelmetHandler.configureHamlet()
    if (
      firstViewState(viewState) &&
      !nonAuthRoutes.some((route) => route.path === location.pathname) &&
      location.pathname !== "/"
    ) {
      checkSession()
    }
  }, [viewState, checkSession])

  if (location.pathname === "/") return <Redirect to="/signin" />

  return (
    <>
      <AuthResetPasswordSuccessfulMassageComponent />
      <QueryClientProvider client={queryClient}>
        <Switch>
          {nonAuthRoutes.map((route) => (
            <Route key={route.path} {...route} />
          ))}
          <AuthGuard {...props}>
            <AuthenticatedRoutes {...props} authType={authType} />
          </AuthGuard>
        </Switch>
        {appConfirmDialogState && (
          <AppConfirmDialog
            appConfirmDialog={appConfirmDialogState}
            dialogAbortClick={dialogAbortClick}
            dialogConfirmClick={() => appConfirmDialogState && dialogConfirmClick(appConfirmDialogState.nextAction)}
          />
        )}
        {appToastState && <AppToast appToast={appToastState} toastClose={toastClose} />}
      </QueryClientProvider>
    </>
  )
})

const AuthenticatedRoutes = (
  props: AuthTypeProps &
    AdminNavigationViewProps &
    CustomerNavigationViewProps &
    AdminHeaderViewProps &
    CustomerHeaderViewProps &
    AppContainerState &
    RouteComponentProps<AdminPortalRouteParams> &
    ReturnType<typeof mapDispatchToProps>,
) => {
  const { authType, viewState, location } = props
  const routeAreaId = useMemo(() => location.pathname.split("/")[1] ?? "", [location])
  const lastSelectedArea = useMemo(() => viewState?.domainResult?.lastSelectedArea, [viewState])
  const areas = useMemo(() => viewState?.domainResult?.areas ?? [], [viewState])
  const currentAreaId = useMemo(
    () => (routeAreaId === "" || routeAreaId === "areas" ? lastSelectedArea?.id : routeAreaId) ?? "",
    [routeAreaId, lastSelectedArea],
  )

  const onboardingRequired = readCookie(`${ONBOARDING_REQUIRED_COOKIE_NAME}-${currentAreaId}`)

  return (
    <ThemeProvider theme={ckwTheme}>
      <AppContainerView>
        {onboardingRequired ? (
          <Switch>
            <Route path="/:areaId/*">
              <Onboarding areaId={currentAreaId} {...props} />
            </Route>
          </Switch>
        ) : (
          <>
            <AppHeaderView {...props} />
            <AppNavigationView areas={areas} currentAreaId={currentAreaId} {...props} />
            <AppPageView {...props} sx={{ mt: 5 }}>
              {authType === AuthType.MANAGER && <ManagerPortalRoutes selectedAreaId={currentAreaId} />}
              {authType === AuthType.ADMINISTRATOR && <AdminPortalRoutes />}
            </AppPageView>
          </>
        )}
      </AppContainerView>
    </ThemeProvider>
  )
}

export const AuthGuard = (props: { viewState: ViewState<AuthSession>; children: React.ReactNode }) => {
  const { viewState, children } = props

  if (viewState.isLoading) return <ProgressIndicator />
  if (viewState.domainError) return signInRouteHandler()
  return <>{children}</>
}

const signInRouteHandler = () => {
  const wasAdmin = localStorage.getItem(COOKIE_ITEMS.userCkwIsAdmin)
  const redirectUrl = `/signin${wasAdmin ? "/admin" : ""}`
  localStorage.removeItem(COOKIE_ITEMS.userCkwIsAdmin)
  return <Redirect to={redirectUrl} />
}
