import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  Medium,
  MeterAttachmentType,
  MeterResponse,
  MeterUpdateAdminRequest,
} from "../../../../../data/generated-sources/openapi"
import { apiFormattedDateToTimestamp } from "../../../../../domain/Domain.Formatters"
import DateUtils from "../../../../../services/utils/DateUtils"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import {
  buildingMetersUsageTypeValues,
  MeterCreateUpdateFormFieldsProps,
  MeterFormRowInterface,
  meterMediumTypeValues,
  powerMeterTypeValues,
  priceModelForMeterInfrastructureValues,
} from "../Meters.Model"
import { MeterCreateInitial } from "./MeterCreateForm"

const MeterFormRow = ({ children, md, xs, p, pr }: MeterFormRowInterface) => (
  <Grid item xs={xs ?? 12} md={md ?? 6} p={p ?? 1} pr={pr ?? 3}>
    {children}
  </Grid>
)

const MeterCreateUpdateFormFields = <T extends MeterCreateInitial | MeterUpdateAdminRequest | MeterResponse>(
  props: MeterCreateUpdateFormFieldsProps<T>,
) => {
  const { values, errors, touched, pricePackagesOptions, isEditMeter, contractStartDate, handleBlur, setFieldValue } =
    props
  const { t } = useTranslation("meters")

  const typedValues = (values as MeterResponse)?.meterAttachmentType

  const isBuildingMeter = typedValues === MeterAttachmentType.BUILDING
  const isZevConfigMeter = typedValues === MeterAttachmentType.ZEV_CONFIG
  const isUnitMeter = typedValues === MeterAttachmentType.UTILITY_UNIT

  const filteredMeterMediumTypeValues = isBuildingMeter
    ? meterMediumTypeValues.filter((v) => v !== Medium.ELECTRICITY)
    : meterMediumTypeValues

  return (
    <Grid container rowGap={1}>
      <MeterFormRow>
        <SingleLineTextField
          autoFocus
          required
          name="meteringCode"
          type="text"
          label={t("label.meterIdSpec")}
          helperText={touched.meteringCode && errors.meteringCode ? errors.meteringCode : undefined}
          value={values.meteringCode}
          onChange={(event) => setFieldValue("meteringCode", event.target.value)}
          onBlur={handleBlur}
        />
      </MeterFormRow>
      <MeterFormRow>
        {isBuildingMeter && (
          <SelectPicker
            name="usageType"
            label={t("label.usageType")}
            value={values?.usageType || ""}
            items={buildingMetersUsageTypeValues.map((type) => ({
              label: t(`label.${type}`),
              value: type,
            }))}
            onChange={(event) => setFieldValue("usageType", event.target.value)}
            onBlur={handleBlur}
          />
        )}
      </MeterFormRow>

      {isEditMeter && (
        <>
          <MeterFormRow>
            <SingleLineDatePicker
              required
              name="billableFrom"
              label={isUnitMeter ? t("label.billableFrom") : t("label.startDate")}
              value={apiFormattedDateToTimestamp(values.billableFrom ?? "")}
              onBlur={handleBlur}
              minDate={contractStartDate ? new Date(contractStartDate) : undefined}
              onChange={(value) => setFieldValue("billableFrom", DateUtils.getDeFormatDate(value))}
            />
          </MeterFormRow>
          <MeterFormRow>
            <SingleLineDatePicker
              name="billableTo"
              label={isUnitMeter ? t("label.billableTo") : t("label.endDate")}
              onBlur={handleBlur}
              value={apiFormattedDateToTimestamp(values.billableTo ?? "")}
              onChange={(value) => setFieldValue("billableTo", DateUtils.getDeFormatDate(value))}
            />
          </MeterFormRow>
        </>
      )}

      <MeterFormRow xs={12} md={12} p={0} pr={0}>
        <MeterFormRow xs={12} md={6}>
          <SelectPicker
            name="medium"
            type="text"
            label={t("label.medium")}
            value={values.medium}
            items={filteredMeterMediumTypeValues.map((type) => ({
              label: t(`label.${type}`),
              value: type,
            }))}
            onBlur={handleBlur}
            onChange={(event) => setFieldValue("medium", event.target.value)}
          />
        </MeterFormRow>
      </MeterFormRow>

      {values.medium === Medium.ELECTRICITY && !isBuildingMeter && !isZevConfigMeter && (
        <>
          <MeterFormRow>
            <SelectPicker
              required
              name="meterDesign"
              label={t("label.meterDesign")}
              value={values.powerMeterType ?? ""}
              items={powerMeterTypeValues.map((type) => ({
                label: t(`shared:powerMeterType.${type}`),
                value: type,
              }))}
              onChange={(event) => setFieldValue("powerMeterType", event.target.value)}
              onBlur={handleBlur}
            />
          </MeterFormRow>
          <MeterFormRow>
            <SelectPicker
              required
              name="pricePackageId"
              label={t("label.price")}
              value={values.pricePackageId ?? ""}
              items={pricePackagesOptions.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onBlur={handleBlur}
              onChange={(event) => setFieldValue("pricePackageId", event.target.value)}
            />
          </MeterFormRow>
        </>
      )}
      <MeterFormRow>
        <SelectPicker
          required
          name="pricingModel"
          label={t("label.priceModel")}
          value={values.pricingModel ?? ""}
          items={priceModelForMeterInfrastructureValues.map((type) => ({
            label: t(`shared:label.pricingModel.${type}`),
            value: type,
          }))}
          onChange={(event) => setFieldValue("pricingModel", event.target.value)}
          onBlur={handleBlur}
        />
      </MeterFormRow>
    </Grid>
  )
}

export default MeterCreateUpdateFormFields
