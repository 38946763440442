import {
  BuildingAdminResponse,
  ContractAdminResponse,
  MeterResponse,
  UtilityUnitResponse,
} from "../../../../../data/generated-sources/openapi"
import {
  contractManagementBuildingColumnComparator,
  contractsColumnComparator,
} from "../contracts/Contracts.Comparator"
import { ContractManagementBuildingColumns, ContractsColumns } from "../contracts/Contracts.Enum"
import ContractsManagementBuildingTableComponent from "../contracts/ContractsManagementBuildingTable.Component"
import { metersColumnComparator } from "../meters/Meters.Comparator"
import { MetersColumns } from "../meters/Meters.Enum"
import { utilityUnitsColumnComparator } from "../utilityUnits/UtilityUnits.Comparator"
import { UtilityUnitsColumns } from "../utilityUnits/UtilityUnits.Enum"

import { getUtilityUnitForBuilding } from "../../../../../domain/portal/admin/buildings/Buildings.Repository"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"
import { EditIcon } from "../../../../../uikit/Shared.Icon"
import { TableAlign } from "../../../../../uikit/table/Table.HeaderView"
import {
  BuildingContractsApiEnum,
  BuildingMetersApiEnum,
  BuildingUtilityUnitsApiEnum,
  ContractsBuildingApiEnum,
} from "../Components.Enums"
import ContractsTableComponent from "../contracts/ContractsTable.Component"
import MetersBuildingTableComponent from "../meters/MetersBuildingTable.Component"
import UtilityUnitTableComponent from "../utilityUnits/UtilityUnitTable.Component"

export const BUILDING_DETAILS_CONFIG = {
  buildingContracts: {
    name: "buildingContracts",
    header: {
      title: "contracts:table.title",
      showButton: false,
      showFilter: false,
    },
    columnEnums: ContractsColumns,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultRowsPerPage: 10,
    defaultSorting: {
      column: ContractsColumns.PRODUCT,
      direction: "desc",
    },
    columnComparator: contractsColumnComparator,
    tableHeaders: [
      {
        column: ContractsColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: ContractsColumns.CONTRACT_NAME,
        label: "contracts:table.header.contractName",
        width: "30%",
        orderable: true,
      },
      {
        column: ContractsColumns.PRODUCT,
        label: "contracts:table.header.product",
        width: "25%",
        orderable: true,
      },
      {
        column: ContractsColumns.START_DATE,
        label: "contracts:table.header.startDate",
        width: "17.5%",
        orderable: true,
      },
      {
        column: ContractsColumns.END_DATE,
        label: "contracts:table.header.endDate",
        width: "17.5%",
        orderable: false,
      },
    ],
    renderTableRows: (item: ContractAdminResponse) => <ContractsTableComponent itemConfig={item} key={item.id} />,
  },
  buildingUtilityUnits: {
    name: "buildingUtilityUnits",
    header: {
      title: "utilityUnit:label.name",
      showButton: true,
      showFilter: false,
    },
    columnEnums: UtilityUnitsColumns,
    apiDefaultParams: {
      page: 1,
      limit: 1000,
      orderBy: "",
    },
    defaultSorting: {
      column: UtilityUnitsColumns.NAME,
      direction: "desc",
    },
    defaultRowsPerPage: 10,
    columnComparator: utilityUnitsColumnComparator,
    getDataFromRepository: getUtilityUnitForBuilding,
    tableHeaders: [
      {
        column: UtilityUnitsColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: UtilityUnitsColumns.NAME,
        label: "utilityUnit:label.name",
        width: "30%",
        orderable: true,
      },
      {
        column: UtilityUnitsColumns.TYPE,
        label: "meters:label.type",
        width: "25%",
        orderable: true,
      },
      {
        column: UtilityUnitsColumns.CURRENT_PARTICIPANT,
        label: "utilityUnit:label.currentParticipant",
        width: "35%",
        orderable: true,
      },
    ],
    renderTableRows: (item: UtilityUnitResponse) => (
      <UtilityUnitTableComponent itemConfig={item} key={item.id} showBuildingLayout={false} />
    ),
  },
  buildingMetersPoints: {
    name: "buildingMetersPoints",
    header: {
      title: "meters:table.title",
      showButton: true,
      showFilter: false,
    },
    columnEnums: MetersColumns,
    apiDefaultParams: {
      page: 1,
      limit: 1000,
      orderBy: "",
    },
    defaultRowsPerPage: 5,
    defaultSorting: {
      column: MetersColumns.METER_ID,
      direction: "desc",
    },
    showPagination: false,
    columnComparator: metersColumnComparator,
    tableHeaders: [
      {
        column: MetersColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: MetersColumns.METER_ID,
        label: "meters:label.meterId",
        width: "30%",
        orderable: true,
      },
      {
        column: MetersColumns.MEDIUM,
        label: "meters:label.medium",
        width: "35%",
        orderable: true,
      },
      {
        column: MetersColumns.PRICING_MODEL_INFRASTRUCTURE,
        label: "meters:label.priceModelInfrastructure",
        width: "35%",
        orderable: true,
      },
    ],
    renderTableRows: (item: MeterResponse) => <MetersBuildingTableComponent itemConfig={item} key={item.id} />,
  },
  contractManagementBuilding: {
    name: "contractManagementBuilding",
    header: {
      title: "contracts-management:contract.buildings.title",
      showButton: true,
      showFilter: false,
      buttonIcon: <EditIcon />,
      redirectUrl: () => `${location.pathname}/building/update`,
    },
    columnEnums: ContractManagementBuildingColumns,
    apiDefaultParams: {
      page: 1,
      limit: 1000,
      orderBy: "",
    },
    defaultRowsPerPage: 10,
    defaultSorting: {
      column: ContractManagementBuildingColumns.STATUS,
      direction: "desc",
    },
    showPagination: false,
    columnComparator: contractManagementBuildingColumnComparator,
    tableHeaders: [
      {
        column: ContractManagementBuildingColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: ContractManagementBuildingColumns.BUILDING,
        label: "contracts-management:contract.building",
        width: "30%",
        orderable: true,
      },
      {
        column: ContractManagementBuildingColumns.STREET,
        label: "contracts-management:contactForm.street",
        width: "25%",
        orderable: true,
        align: TableAlign.left,
      },
      {
        column: ContractManagementBuildingColumns.CITY,
        label: "contracts-management:contactForm.city",
        width: "35%",
        orderable: true,
      },
    ],
    renderTableRows: (item: BuildingAdminResponse) => (
      <ContractsManagementBuildingTableComponent itemConfig={item} key={getUniqueId()} />
    ),
  },
}

export const BUILDING_DETAILS_API_CONFIG = {
  buildingUtilityUnits: BuildingUtilityUnitsApiEnum,
  buildingContracts: BuildingContractsApiEnum,
  buildingMetersPoints: BuildingMetersApiEnum,
  contractManagementBuilding: ContractsBuildingApiEnum,
}
