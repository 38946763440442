import { Box, CircularProgress, Grid, Paper } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import { MeterAttachmentType, MeterState } from "../../../../data/generated-sources/openapi"
import { StatusType } from "../../../../domain/Domain.Model"
import { adminDeleteMeterById, getMeterById } from "../../../../domain/portal/admin/meters/Meters.Repository"
import DateUtils from "../../../../services/utils/DateUtils"
import { DataItemBoxNewDesign } from "../../../../uikit/box/DataItemBox"
import { PrimaryEditButton } from "../../../../uikit/button"
import { ConfirmDialog } from "../../../../uikit/confirmDialog/ConfirmDialog"
import { StatusView } from "../../../../uikit/label/StatusView"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { RemoveIcon } from "../../../../uikit/Shared.Icon"
import { AxiosErrorDataType, StatusTypeObjectKey } from "../../../Shared.Utils"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import MeterAnalytics from "./components/MeterAnalytics"

const MeterDetailComponent = ({ match: { params }, history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("meters")
  const [meterError, setMeterError] = useState<AxiosErrorDataType>()

  const {
    data: meter,
    isFetching,
    remove,
  } = useQuery(["getMeter"], () => getMeterById(params?.meterId), {
    enabled: !!params.meterId,
    onError: setMeterError,
  })

  const { mutate: deleteMeter, isLoading: isDeletingMeter } = useMutation(
    ["deleteMeter"],
    () => adminDeleteMeterById(params.meterId),
    {
      onSuccess: () => history.goBack(),
      onError: setMeterError,
    },
  )

  useEffect(() => () => remove(), [remove])

  const isBuildingMeter = meter?.meterAttachmentType === MeterAttachmentType.BUILDING
  const isUnitMeter = meter?.meterAttachmentType === MeterAttachmentType.UTILITY_UNIT
  const deletedBtnDisabled =
    meter?.activeState !== MeterState.DRAFT || meter?.meterAttachmentType === MeterAttachmentType.ZEV_CONFIG

  if (isFetching) {
    return <CircularProgress color="primary" />
  }

  return (
    <>
      <Paper>
        <ErrorAlert
          visible={!!meterError}
          message={t(`error-codes:${meterError?.response?.data?.code || meterError?.code || "OTHER"}`)}
        />
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Box p={1} pb={2}>
              <StatusView statusType={StatusType[meter?.activeState as StatusTypeObjectKey]} />
            </Box>
          </Grid>
          <Grid container item xs={12} sm={6} alignItems={"center"} justifyContent={"flex-end"} sx={{ p: 1, pb: 1 }}>
            <PrimaryEditButton onClick={() => history.push(`/meters/${meter?.id}/update`, meter)} />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ py: 1 }}>
            <DataItemBoxNewDesign title={t("label.meterIdSpec")} value={meter?.meteringCode} />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ py: 1 }}>
            {isBuildingMeter && (
              <DataItemBoxNewDesign
                title={t("label.usageType")}
                value={meter?.usageType ? t(`label.${meter?.usageType}`) : "-"}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ py: 1 }}>
            <DataItemBoxNewDesign
              title={isUnitMeter ? t("label.billableFrom") : t("label.startDate")}
              value={meter?.billableFrom ? DateUtils.getDeFormatDateWithMonthString(meter?.billableFrom) : "-"}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ py: 1 }}>
            <DataItemBoxNewDesign
              title={isUnitMeter ? t("label.billableTo") : t("label.endDate")}
              value={meter?.billableTo ? DateUtils.getDeFormatDateWithMonthString(meter.billableTo) : "-"}
            />
          </Grid>
          <Grid item xs={12} sx={{ py: 1 }}>
            <DataItemBoxNewDesign title={t("label.medium")} value={t(`label.${meter?.medium}`)} />
          </Grid>

          {meter?.meterAttachmentType === MeterAttachmentType.UTILITY_UNIT && (
            <>
              <Grid item xs={12} sm={6} sx={{ py: 1 }}>
                <DataItemBoxNewDesign
                  title={t("label.meterDesign")}
                  value={meter?.powerMeterType ? t(`shared:powerMeterType.${meter?.powerMeterType}`) : "-"}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ py: 1 }}>
                <DataItemBoxNewDesign title={t("label.price")} value={meter?.pricePackageName ?? "-"} />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} sx={{ py: 1 }}>
            <DataItemBoxNewDesign
              title={t("label.priceModel")}
              value={t(`shared:label.pricingModel.${meter?.pricingModel}`)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={12} sx={{ mt: 4, pb: 0 }}>
            <ConfirmDialog
              actionButtonText={t("label.deleteMeter")}
              actionButtonStartIcon={<RemoveIcon />}
              actionButtonLoading={isDeletingMeter}
              actionButtonDisabled={deletedBtnDisabled}
              onConfirm={deleteMeter}
              dialogTitle={t("label.deleteMeter.confirmMsg")}
            />
          </Grid>
        </Grid>
      </Paper>
      {meter?.meteringCode && (
        <Paper>
          <MeterAnalytics meterId={meter.meteringCode} />
        </Paper>
      )}
    </>
  )
}

export default MeterDetailComponent
