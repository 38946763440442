import { TableCell } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { MeterResponse } from "../../../../../data/generated-sources/openapi/api"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"

interface IUtilityUnitTableMeters extends RouteComponentProps<AdminPortalRouteParams>, MeterResponse {}

export const UtilityUnitTableMeters = withRouter(({ history, ...props }: IUtilityUnitTableMeters) => {
  const { id, meteringCode, activeState, medium, pricingModel } = props
  const { t } = useTranslation("meters")
  const onRowClick = () => history.push(`/meters/${id}`)

  return (
    <TableRowClickable key={id} rowData={{ id }} rowClick={onRowClick}>
      <TableCell align="left">
        <StatusView statusType={StatusType[activeState]} />
      </TableCell>
      <TableCell align="left">{meteringCode}</TableCell>
      <TableCell align="left">{t(`label.${medium}`)}</TableCell>
      <TableCell align="left">{pricingModel ? t(`shared:label.pricingModel.${pricingModel}`) : ""}</TableCell>
    </TableRowClickable>
  )
})
