import { Paper } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Form, Formik, FormikHelpers } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import {
  Medium,
  MeterUpdateAdminRequest,
  PowerMeterType,
  PricingModel,
} from "../../../../../data/generated-sources/openapi"
import { createMeterForUtilityUnit } from "../../../../../domain/portal/admin/meters/Meters.Repository"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"

import { getContractManagementById } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { getPricePackagesByUtilityUnitId } from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { ErrorAlert, SuccessAlertLink } from "../../../../../uikit/Shared.Alert"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"
import MeterCreateUpdateFormFields from "./MeterCreateUpdateFormFields"
import { meterCreateSchema } from "./MetersValidation.Schema"

export interface MeterCreateInitial {
  meteringCode: string
  billableFrom: string
  billableTo?: string
  pricePackageId: number
  medium: string
  meterType: string
  powerMeterType?: string
  usageType?: string
  pricingModel: string
}

const initialMeterFormValues: MeterCreateInitial = {
  meteringCode: "",
  billableFrom: "",
  pricePackageId: 0,
  medium: "",
  meterType: "",
  powerMeterType: "",
  usageType: "",
  pricingModel: "",
}

const MeterCreateForm = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const {
    match: { params },
    history,
  } = props
  const { t } = useTranslation("meters")
  const [meterError, setMeterError] = useState<AxiosErrorDataType>()

  const { data: pricePackagesData, remove: removePrices } = useQuery(
    ["getPricePackages"],
    () => getPricePackagesByUtilityUnitId(params?.utilityUnitId),
    {
      enabled: !!params?.utilityUnitId,
      ...useQueryDefaultOptions,
    },
  )

  const { data: contract, remove: removeContract } = useQuery(
    ["getContract"],
    () => getContractManagementById(pricePackagesData?.contractId ?? ""),
    {
      enabled: !!pricePackagesData?.contractId,
      ...useQueryDefaultOptions,
      onError: setMeterError,
    },
  )

  useEffect(() => {
    return () => {
      removePrices()
      removeContract()
    }
  }, [removePrices, removeContract])

  const {
    mutate: createMeter,
    isLoading: isMeterCreating,
    isSuccess: isCreateMeterSuccess,
    data: createdMeterUrl,
  } = useMutation(
    ["createMeter"],
    (meterData: MeterUpdateAdminRequest) => createMeterForUtilityUnit(params.utilityUnitId, meterData),
    {
      onError: setMeterError,
    },
  )

  const handleOnSubmit = (values: MeterCreateInitial, helper: FormikHelpers<MeterCreateInitial>) => {
    const billableFrom = values.billableFrom ? values.billableFrom : contract?.startDate ?? ""
    const meterData: MeterUpdateAdminRequest = {
      meteringCode: values.meteringCode,
      billableFrom: billableFrom,
      pricePackageId: values.pricePackageId,
      medium: Medium[values.medium as Medium],
      ...(PowerMeterType[values.powerMeterType as PowerMeterType] && {
        powerMeterType: PowerMeterType[values.powerMeterType as PowerMeterType],
      }),
      ...(values.billableTo && { billableTo: values.billableTo }),
      pricingModel: values.pricingModel as PricingModel,
    }
    if (values.medium !== Medium.ELECTRICITY) {
      delete meterData.pricePackageId
      delete meterData.powerMeterType
    }
    setMeterError(undefined)
    createMeter(meterData, {
      onSuccess: () => {
        helper.resetForm()
      },
    })
  }

  return (
    <>
      <SuccessAlertLink
        message={t("successMsg.text")}
        visible={isCreateMeterSuccess}
        onClick={() => history.push(`/meters/${String(createdMeterUrl).split("/").at(-1)}`)}
      />
      <ErrorAlert
        visible={!!meterError}
        message={t(`error-codes:${meterError?.response?.data?.code || meterError?.code || "OTHER"}`)}
      />
      <Formik<MeterCreateInitial>
        initialValues={initialMeterFormValues}
        validationSchema={meterCreateSchema}
        onSubmit={handleOnSubmit}
      >
        {(props) => (
          <Form>
            <Paper>
              <MeterCreateUpdateFormFields<MeterCreateInitial>
                {...props}
                isEditMeter={false}
                contractStartDate={contract?.startDate}
                pricePackagesOptions={pricePackagesData?.pricePackages ?? []}
              />
            </Paper>
            <FormUpdateActionsView
              {...props}
              isLoading={isMeterCreating}
              navigateBack={() => history.goBack()}
              buttonCtaLabel={t("label.saveChanges")}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default MeterCreateForm
