import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { UtilityUnitParticipationParticipant } from "../../../../../domain/participant/Participant.Model"
import { deleteUtilityUnitParticipationMoveOut } from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import DateUtils from "../../../../../services/utils/DateUtils"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

import CancelIcon from "@mui/icons-material/Cancel"
import { CancelButton } from "../../../../../uikit/button/CancelButton"
import { PrimaryButton } from "../../../../../uikit/button/PrimaryButton"
import { EditIcon } from "../../../../../uikit/Shared.Icon"
import { ParticipantMoveOutDateEdit } from "./ParticipantMoveOutDateEdit"
import { UtilityUnitMoveInDialog } from "./UtilityUnitMoveInDialog"
import { UtilityUnitMoveOutDialog } from "./UtilityUnitMoveOutDialog"

interface UtilityUnitParticipantMoveOutCellProps {
  participation: UtilityUnitParticipationParticipant
  onUpdateSuccess: () => void
  onUpdateError: (error: AxiosErrorDataType) => void
}

export const UtilityUnitParticipantMoveOutCell = ({
  participation,
  onUpdateSuccess,
  onUpdateError,
}: UtilityUnitParticipantMoveOutCellProps) => {
  const { moveOutDate, participant, isLatestTenant } = participation
  const { t } = useTranslation("utilityUnitParticipations")
  const [editMode, setEditMode] = useState(false)
  const [showConfirmMoveOutDelete, setShowMoveOutConfirmDelete] = useState(false)

  const handleUpdateSuccess = () => {
    onUpdateSuccess()
    setEditMode(false)
    setShowMoveOutConfirmDelete(false)
  }

  const { mutate: deleteParticipationMoveOut, isLoading: isDeleting } = useMutation(
    ["deleteParticipation"],
    () => deleteUtilityUnitParticipationMoveOut(participation.utilityUnitId, participation.id),
    {
      onSuccess: handleUpdateSuccess,
      onError: onUpdateError,
    },
  )

  const MoveOutDateLayout = useCallback(() => {
    return (
      <>
        <Typography fontWeight={400} fontSize={14} alignItems={"center"} pr={0.5}>
          {moveOutDate ? DateUtils.getDeFormatWithDots(moveOutDate) : "-"}
        </Typography>
        {isLatestTenant && moveOutDate && (
          <>
            <EditIcon color="disabled" fontSize="small" onClick={() => setEditMode(true)} cursor="pointer" />
            <Tooltip title={t("tooltip.deleteMoveOut")}>
              <IconButton size="small" color="default" onClick={() => setShowMoveOutConfirmDelete(true)}>
                {isDeleting ? <CircularProgress size={20} /> : <CancelIcon />}
              </IconButton>
            </Tooltip>
          </>
        )}

        <Dialog open={showConfirmMoveOutDelete} onClose={() => setShowMoveOutConfirmDelete(false)} fullWidth>
          <Box p={2}>
            <DialogContent>{t("delete.moveOut.dialog.body")}</DialogContent>
            <DialogActions sx={{ justifyContent: "space-between", py: 2 }}>
              <CancelButton label={t("shared:dialog.close.cta")} onClick={() => setShowMoveOutConfirmDelete(false)} />
              <PrimaryButton
                label={t("shared:dialog.confirm.cta")}
                onClick={() => {
                  setShowMoveOutConfirmDelete(false)
                  deleteParticipationMoveOut()
                }}
              />
            </DialogActions>
          </Box>
        </Dialog>
      </>
    )
  }, [moveOutDate, participant, participation, isDeleting, showConfirmMoveOutDelete, deleteParticipationMoveOut, t])

  if (editMode) {
    return (
      <ParticipantMoveOutDateEdit
        participation={participation}
        onEditClose={() => setEditMode(false)}
        onUpdateSuccess={handleUpdateSuccess}
        onUpdateError={onUpdateError}
      />
    )
  }

  if (moveOutDate) {
    return <MoveOutDateLayout />
  }

  return (
    <>
      {participant ? (
        <UtilityUnitMoveOutDialog participation={participation} onMoveOutSuccess={onUpdateSuccess} />
      ) : (
        <UtilityUnitMoveInDialog participation={participation} onUpdateSuccess={onUpdateSuccess} />
      )}
    </>
  )
}
